import React from "react"
import styled, { withTheme } from "styled-components"
import { headingFont } from "mill/utils/settings"

const styleSwitcher = style => {
  switch (style) {
    case "h1":
      return `
        font-size: 2.6rem;
        font-family: ${headingFont};
      `
    default:
      return ""
  }
}

const sizeSwitcher = size => {
  switch (size) {
    case 1:
      return `
        font-size: 2.6rem;
      `
    case 4:
      return `
        font-size: 1.4rem;
        letter-spacing: 1.05px;
      `
    default:
      return ""
  }
}

const Heading = props => {
  const HeadingRank = props.rank > 6 ? "h6" : `h${props.rank}`
  const Tag = styled(HeadingRank)`
    ${() => styleSwitcher(HeadingRank)}
    color: ${props => (props.color ? props.color : props.theme.colors.primary)};
    ${props => sizeSwitcher(props.size)}
    font-weight: bold;

    ${props =>
      props.uppercase &&
      `
      text-transform: uppercase
    `};

    small {
      display: block;
      font-weight: 300;
      line-height: 1em;
    }
  `

  return (
    <Tag size={props.size} uppercase={props.uppercase} color={props.color}>
      {props.children}
    </Tag>
  )
}

export default withTheme(Heading)
