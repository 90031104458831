import Link from "shared/components/Link"
import styled from "styled-components"

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 2rem;
  margin: 2rem auto 0 auto;
  display: inline-block;
`

export const ListItem = styled.li`
  display: block;
  float: left;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 15px;
  border: 2px solid #ccc;
  margin-right: 1rem;
  transition: 0.1s all ease-out;

  ${props =>
    props.active &&
    `
    border-color: ${props.theme.colors.primary};

    a {
      transition: 0.1s all ease-in;
      border: 2px solid white;
      background-color: ${props.theme.colors.primary};
    }
  `} &:hover {
    border-color: ${props => props.theme.colors.primary};
    transition: 0.1s all ease-in;
    cursor: pointer;

    a {
      transition: 0.1s all ease-in;
      border: 2px solid white;
      background-color: ${props => props.theme.colors.primary};
    }
  }
`

export const ListAnchor = styled(Link)`
  transition: 0.1s all ease-out;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 15px;
  background-color: white;
  margin: 0;
  padding: 0;
`
