import React from "react"
import { List, ListItem, ListAnchor } from "./styles"

const ProgresssStepCircleBar = props => {
  return (
    <div className="clearfix">
      <List>
        {props.steps.map(step => {
          const isCurrentStep = props.currentStep == props.steps.indexOf(step)
          return (
            <ListItem active={isCurrentStep} key={props.steps.indexOf(step)}>
              <ListAnchor to={step} />
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

export default ProgresssStepCircleBar
